import React from "react";
import { LanguageProvider } from "./src/context/LanguageContext";
import "sanitize.css/sanitize.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./src/styles/style.scss";
import { ConsentManager } from '@iziwork/consent-manager';
import inEU  from '@segment/in-eu';

export function onPreRouteUpdate() {
  if (analytics) {
    analytics.page();
  }
}
export function wrapRootElement({ element }) {
  if (typeof window !== "undefined") {
    //Load Segment for Consent Manager
    var analytics = (window.analytics = window.analytics || []);
    if (!analytics.initialize) {
      if (analytics.invoked) {
        window.console &&
          console.error &&
          console.error("Segment snippet included twice.");
      } else {
        analytics.invoked = !0;
        analytics.methods = [
          "trackSubmit",
          "trackClick",
          "trackLink",
          "trackForm",
          "pageview",
          "identify",
          "reset",
          "group",
          "track",
          "ready",
          "alias",
          "debug",
          "page",
          "once",
          "off",
          "on",
          "addSourceMiddleware",
          "addIntegrationMiddleware",
          "setAnonymousId",
          "addDestinationMiddleware",
        ];
        analytics.factory = function (e) {
          return function () {
            var t = Array.prototype.slice.call(arguments);
            t.unshift(e);
            analytics.push(t);
            return analytics;
          };
        };
        for (var e = 0; e < analytics.methods.length; e++) {
          var key = analytics.methods[e];
          analytics[key] = analytics.factory(key);
        }
        analytics.load = function (key, e) {
          var t = document.createElement("script");
          t.type = "text/javascript";
          t.async = !0;
          t.src =
            "https://cdn.segment.com/analytics.js/v1/" +
            key +
            "/analytics.min.js";
          var n = document.getElementsByTagName("script")[0];
          n.parentNode.insertBefore(t, n);
          analytics._loadOptions = e;
        };
        analytics.SNIPPET_VERSION = "4.15.2";
      }
    }
  }

  //Detect language in url or of the navigator for Consent Manager
  let lang;
  if (
    window.location.href.indexOf("/it") > -1 ||
    navigator.language === "it-IT"
  ) {
    lang = "it";
  } else if (
    window.location.href.indexOf("/fr") > -1 ||
    navigator.language === "fr-FR"
  ) {
    lang = "fr";
  } else {
    lang = navigator.language;
  }

  let bannerContent;
  let bannerSubContent;
  let preferencesDialogTitle;
  let preferencesDialogContent;
  if (window.location.href.indexOf("/fr") > -1) {
    bannerContent = (
      <span>
        Notre site internet utilise des cookies{" "}
        <span role="img" aria-label="cookie">
          🍪
        </span>
        . Certains ne peuvent être refusés pour le bon fonctionnement du site.
        Pour les autres, vous pouvez choisir de les paramétrer . [Accepter]
      </span>
    );
    bannerSubContent = "en cliquant ici";
    preferencesDialogTitle = "Gérer mes préférences";
    preferencesDialogContent =
      "Nous utilisons l’information collectée par des cookies et des librairies JavaScript " +
      "pour améliorer votre expérience de navigation, analyser le trafic sur notre site, afficher des publicités personnalisées " +
      "et augmenter la performance de notre site.";
  } else if (window.location.href.indexOf("/it") > -1) {
    bannerContent = (
      <span>
        Questo sito utilizza i cookies{" "}
        <span role="img" aria-label="cookie">
          🍪
        </span>
        . I cookie di questo tipo sono necessari per il corretto funzionamento
        di alcune aree del sito e non possono essere rimossi durante la
        navigazione. Altri invece, possono essere impostati e gestiti secondo le
        proprie preferenze cliccando qui . [Accetta]
      </span>
    );
    bannerSubContent = "cliccando qui";
    preferencesDialogTitle = "Gestisci le mie preferenze";
    preferencesDialogContent = (
      <span>
        Benvenuti!<br/><br/>
        Utilizziamo i cookie per migliorare la tua esperienza sul nostro sito. Proseguendo con la navigazione, accetti l&#39;uso dei cookie in conformità con la nostra Cookie Policy. Puoi modificare le tue preferenze sui cookie in qualsiasi momento. <a href="/it/politica-sulluso-dei-cookie" target="_blank" rel="noreferrer">Puoi trovare la nostra Cookie Policy completa qui</a>
      </span>
    );
  }

  return (
    <>
      <LanguageProvider>
        {element}
        <div className="consent-banner">
          <ConsentManager
            writeKey={process.env.GATSBY_SEGMENT_IO_KEY}
            shouldRequireConsent={inEU}
            bannerContent={bannerContent}
            bannerSubContent={bannerSubContent}
            bannerTextColor={"#031b4a"}
            bannerBackgroundColor={"white"}
            preferencesDialogTitle={preferencesDialogTitle}
            preferencesDialogContent={preferencesDialogContent}
            closeBehavior="accept"
            lang={lang}
            allowSmallBannerOnClose={true}
          />
        </div>
      </LanguageProvider>
    </>
  );
}
